import parse from "html-react-parser";

// styles
import styles from "./Alert.module.scss";

// components
import Icon from "../Icon/Icon";

// functions
import { findGlobalContent } from "../../functions/findGlobalContent";

const Alert = ({ globalData = {} }) => {
  const richtextDefaultValue =
    "<p>Just nu har vi högt tryck på sidan och det kan ta tid mellan stegen när du fyller i dina uppgifter. Om du upplever problem ber vi dig att vänta några minuter och testa igen. Du är välkommen att kontakta oss på 08-588 891 58 eller givarservice@kvinnatillkvinna.se om du behöver hjälp.</p>";

  const showMonthlyGiftAlert = findGlobalContent({
    globalData,
    key: "toggle_showMonthlyGiftAlert",
    defaultValue: true,
  });

  const textContent = parse(
    findGlobalContent({
      globalData,
      key: "text_monthlyGiftAlert",
      defaultValue: richtextDefaultValue,
    })
  );

  if (showMonthlyGiftAlert) {
    return (
      <div className={styles["alert"]}>
        <Icon variant="info-triangle" className={styles["icon"]} />

        <div className={styles["richtext"]}>{textContent}</div>
      </div>
    );
  } else {
    return null;
  }
};

export default Alert;
