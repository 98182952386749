import { useCallback, useContext, useRef, useState } from "react";

// hooks
import { useValidateFetchedAccountFields } from "../../../../../hooks/useFetchedFields";

// functions
import { AppdataContext, AppdataDispatchContext } from "../../../../../functions/appdataContext";
import { initiateGetBankAccounts, handleAbortBankAccounts as handleAbort } from "../../../../../functions/billectaCalls";
import { deviceType } from "../../../../../functions/ua";
import { findGlobalContent } from "../../../../../functions/findGlobalContent";

// components
import InputField from "../../../../InputField/InputField";
import Button from "../../../../Button/Button";
import Modal from "../../../../Modal/Modal";
import Card from "../../../../Card/Card";
import Select from "../../../../Select/Select";
import BankID from "../../../../BankID/BankID";
import Typography from "../../../../Typography/Typography";

// styles
import styles from "./BankAccountForm.module.scss";

const BankaccountForm = ({
  globalData,
  formData,
  onChangeInput,
  titlePrefixNumber,
  keys,
  texts,
  labels,
  placeholderOptions,
  manualBankAccountFormIsOpen,
  setManualBankAccountFormIsOpen,
  handleInputFocus = () => {},
  handleValidation = () => {},
}) => {
  const [bankAccountWasFetched, setBankAccountWasFetched] = useState(false);
  const appdata = useContext(AppdataContext);
  const dispatch = useContext(AppdataDispatchContext);
  const stateRef = useRef();

  stateRef.current = appdata;

  const banks = appdata?.initialServerData?.billecta?.banks;
  const bankAccounts = appdata?.billectaAutogiro?.bankAccounts;
  const bank = formData?.bank;
  const billectaRequestState = appdata?.billectaAutogiro?.requestState;
  const billectaErrorMessage = appdata?.billectaAutogiro?.errorMessage;
  const qrCode = appdata?.billectaAutogiro?.qrCode;
  const bankIdAutostartToken = appdata?.billectaAutogiro?.bankIdAutostartToken;

  const isMobile = deviceType === "mobile";
  const isIOS = navigator?.userAgent && /iPad|iPhone|iPod/.test(navigator.userAgent);

  const shouldShowModal =
    billectaRequestState === "requesting-bankaccounts" ||
    billectaRequestState === "polling-bankaccounts" ||
    billectaRequestState === "failed-bankaccounts" ||
    billectaRequestState === "success-bankaccounts";

  const canFetchBankAccount =
    bank &&
    bank !== "OTHER" &&
    formData?.socialsecuritynumber?.length === 12

  const performInitiateGetBankAccounts = () => {
    if (formData?.bank) {
      initiateGetBankAccounts(
        {
          bank: formData?.bank,
          socialsecuritynumber: formData?.socialsecuritynumber,
          otherDevice: !isMobile,
        },
        stateRef,
        dispatch
      );
      setManualBankAccountFormIsOpen(true);
    } else {
      // todo open dropdown
    }
  };

  const onSelectBankAccount = () => {
    const accountIndex = formData.bankaccountselector;
    //console.log(accountIndex);
    if (accountIndex === "") {
      return;
    }

    const account = bankAccounts[accountIndex];
    dispatch({
      type: "setFormValue",
      value: { name: "account", value: account.AccountNo },
    });
    dispatch({
      type: "setFormValue",
      value: { name: "clearing", value: account.ClearingNo.substring(0,4) },
    });
    setManualBankAccountFormIsOpen(true);
    setBankAccountWasFetched(true);
    dispatch({ type: "setBillectaStatus", value: "idle" });
  };

  const openBankIdApp = useCallback(() => {
    const linkBase = isIOS ? `https://app.bankid.com/` : `bankid:///`;

    if (bankIdAutostartToken) {
      window.location = `${linkBase}?autostarttoken=${bankIdAutostartToken}&redirect=null`;
    } else {
      window.location = `${linkBase}?redirect=null`;
    }
  }, [bankIdAutostartToken, isIOS]);

  useValidateFetchedAccountFields(bankAccountWasFetched)

  return (
    <>
      <Card
        title={`${titlePrefixNumber}. ${findGlobalContent({
          globalData,
          key: keys.title,
          defaultValue: texts.title,
        })}`}
        text={findGlobalContent({ globalData, key: keys.text })}
      >
        <div className={styles["content-wrapper"]}>
          {banks && banks.length && (
            <>
              <Select
                id="dropdown-select-bank"
                name="bank"
                onChange={onChangeInput}
                label={findGlobalContent({
                  globalData,
                  key: keys.bankSelect,
                  innerKey: "label",
                  defaultValue: labels.bankSelect,
                })}
              >
                <option value="">
                  {findGlobalContent({
                    globalData,
                    key: keys.bankSelect,
                    innerKey: "placeholderOption",
                    defaultValue: placeholderOptions.bankSelect,
                  })}
                </option>

                {banks.map((element, index) => (
                  <option key={index} value={element.id}>
                    {element.name}
                  </option>
                ))}
              </Select>
              
              <div>
                <Button
                  onClick={performInitiateGetBankAccounts}
                  disabled={!canFetchBankAccount}
                  isBankIdButton
                  id="get-bank-account-button"
                >
                  {findGlobalContent({
                    globalData,
                    key: keys.getBankAccount,
                    defaultValue: texts.getBankAccount,
                  })}
                </Button>

                {Boolean(
                  !canFetchBankAccount && !manualBankAccountFormIsOpen
                ) && (
                  <Typography variant="button-hint-text">
                    {findGlobalContent({
                      globalData,
                      key: keys.getBankAccountHint,
                      defaultValue: texts.getBankAccountHint,
                    })}
                  </Typography>
                )}
              </div>

              <Modal
                isActive={shouldShowModal}
                closeModal={() => handleAbort(dispatch)}
                variant="white"
                size="small"
              >
                {billectaRequestState !== "success-bankaccounts" && (
                  <BankID
                    title={findGlobalContent({
                      globalData,
                      key: keys.useBankId,
                      defaultValue: texts.useBankId,
                    })}
                    showLoader={billectaRequestState === "requesting-bankaccounts"}
                    qr={billectaRequestState === "polling-bankaccounts" && qrCode ? qrCode : null}
                    billectaRequestState={billectaRequestState}
                    billectaErrorMessage={billectaErrorMessage}
                    openBankIdApp={openBankIdApp}
                  />
                )}

                {bankAccounts && billectaRequestState === "success-bankaccounts" && (
                  <div className={styles["bankaccount-selector-wrapper"]}>
                    <Select
                      id="bankaccountselector"
                      name="bankaccountselector"
                      onChange={onChangeInput}
                      label={findGlobalContent({
                        globalData,
                        key: keys.bankAccountSelect,
                        innerKey: "label",
                        defaultValue: labels.bankAccountSelect,
                      })}
                    >
                      <option value="">
                        {findGlobalContent({
                          globalData,
                          key: keys.bankSelect,
                          innerKey: "placeholderOption",
                          defaultValue: placeholderOptions.bankAccountSelect,
                        })}
                      </option>

                      {bankAccounts.map((account, index) => (
                        <option key={index} value={index}>
                          {`${account.Name} - ${account.AccountNo}`}
                        </option>
                      ))}
                    </Select>
                    <Button 
                      variant="primary"
                      onClick={() => onSelectBankAccount()}
                    >Välj {/* TODO add field for this */}</Button>
                  </div>
                )}
              </Modal>
            </>
          )}

          {!manualBankAccountFormIsOpen && (
            <Button
              variant="tertiary"
              onClick={() => setManualBankAccountFormIsOpen(true)}
              id="open-manual-bank-account-form-button"
            >
              {globalData?.button_manualBankAccount
                ? globalData.button_manualBankAccount
                : "Fyll i kontouppgifterna själv"}
            </Button>
          )}

          {manualBankAccountFormIsOpen && (
            <div className={styles["account-input-wrapper"]}>
              <InputField
                type="tel"
                name="clearing"
                id="clearing-number-input"
                value={formData.clearing}
                onChange={onChangeInput}
                onFocus={(event) => handleInputFocus(event)}
                onBlur={(event) => handleValidation(event)}
                globalKey={keys.bankClearing}
                defaultValues={{
                  label: labels.bankClearing,
                }}
              />

              <InputField
                type="tel"
                name="account"
                id="account-number-input"
                value={formData?.account}
                onChange={onChangeInput}
                onFocus={(event) => handleInputFocus(event)}
                onBlur={(event) => handleValidation(event)}
                globalKey={keys.bankAccount}
                defaultValues={{
                  label: labels.bankAccount,
                }}
              />
            </div>
          )}
        </div>
      </Card>
    </>
  );
};

export default BankaccountForm;
