import { useContext, useState } from "react";
import axios from "axios";

// hooks
import { useValidateFetchedAddressFields } from "../../../hooks/useFetchedFields";

// functions
import { AppdataContext, AppdataDispatchContext } from "../../../functions/appdataContext";
import { findGlobalContent } from "../../../functions/findGlobalContent";
import { formatMaskedString } from "../../../functions/maskString";

// components
import InputField from "../../InputField/InputField";
import Button from "../../Button/Button";
import Card from "../../Card/Card";
import Typography from "../../Typography/Typography";

const PersonalDetailsForm = ({
  globalData = {},
  manualPersonalDetailsFormIsOpen,
  setManualPersonalDetailsFormIsOpen,
  titlePrefixNumber,
  triggerSaveLead,
  onChangeInput = () => {},
  handleInputFocus = () => {},
  handleInputBlur = () => {},
}) => {
  const appdata = useContext(AppdataContext);
  const dispatch = useContext(AppdataDispatchContext);
  const { formData } = appdata;
  const [addressIsFetched, setAdressIsFetched] = useState(false);
  const [getAddressError, setGetAddressError] = useState(null);

  // global content keys
  const keys = {
    title: "title_personalDetails",
    textPersonalDetails: "text_personalDetails",
    inputEmail: "input_email",
    inputPhone: "input_phone",
    inputSsn: "input_ssn",
    buttonGetAddress: "button_getAddress",
    getAddressHint: "text_getAddressHint",
    buttonManualDetails: "button_manualContactDetails",
    inputFirstname: "input_firstname",
    inputLastname: "input_lastname",
    inputAddress: "input_address",
    inputZip: "input_zip",
    inputCity: "input_city",
  };

  // default values - input field labels
  const labels = {
    inputEmail: "Din e-post",
    inputPhone: "Ditt telefonnummer",
    inputSsn: "Personnummer",
    inputFirstname: "Förnamn",
    inputLastname: "Efternamn",
    inputAddress: "Adress",
    inputZip: "Postnummer",
    inputCity: "Ort",
  };

  // default values - input field placeholders
  const placeholders = {
    inputSsn: "ååååmmddxxxx",
  };

  // default values - other
  const texts = {
    title: "Dina personuppgifter",
    textPersonalDetails: "När du går vidare sparar vi dina uppgifter så vi kan skicka dig en bekräftelse.",
    buttonGetAddress: "Hämta adress",
    getAddressHint: "Fyll i personnummer för att kunna hämta din adress.",
    buttonManualDetails: "Fyll i kontaktuppgifterna själv",
  };

  // WP: Get adress (Billecta)
  const getAddress = async (event) => {
    event.preventDefault();
    setGetAddressError(null);

    console.log("Get address");

    const { formData } = appdata;
    const { ajax_url, nonce } = appdata?.backendSetup;
    const ssn = formData?.socialsecuritynumber;

    if (!ssn) return;

    let get_address_data = new FormData();
    get_address_data.set("action", "ktk_donations_get_address");
    get_address_data.set("nonce", nonce);
    get_address_data.set("ssn", ssn);

    try {
      const res = await axios.post(ajax_url, get_address_data);

      if (res.data && res.data.FirstName) {
        dispatch({
          type: "setFormData",
          value: {
            ...formData,
            firstname: formatMaskedString(res.data.FirstName),
            lastname: formatMaskedString(res.data.LastName),
            address: formatMaskedString(res.data.Address),
            zip: formatMaskedString(res.data.Zipcode, true),
            city: formatMaskedString(res.data.City),
            unmasked_firstname: res.data.FirstName,
            unmasked_lastname: res.data.LastName,
            unmasked_address: res.data.Address,
            unmasked_zip: res.data.Zipcode,
            unmasked_city: res.data.City,
            isMaskedPersonalDetails: true,
            usedAddressLookup: true
          },
        });
        setAdressIsFetched(true);

        // TODO - create better method for assuring data has been saved before triggering saveLead
        setTimeout(() => {
          triggerSaveLead();
        }, 500);
      } else {
        console.error("Something went wrong");
        setGetAddressError("Adress kunde inte hämtas.");
        setManualPersonalDetailsFormIsOpen(true);
      }
    } catch (e) {
      console.error(e);
      setGetAddressError("Adress kunde inte hämtas.");
      setManualPersonalDetailsFormIsOpen(true);
    }
  };

  useValidateFetchedAddressFields(addressIsFetched);

  return (
    <Card
      title={`${titlePrefixNumber}. ${findGlobalContent({
        globalData,
        key: keys.title,
        defaultValue: texts.title,
      })}`}
      text={findGlobalContent({
        globalData,
        key: keys.textPersonalDetails,
        defaultValue: texts.textPersonalDetails,
      })}
    >
      <InputField
        name="email"
        id="email-input"
        value={formData.email}
        onChange={onChangeInput}
        onFocus={(event) => handleInputFocus(event)}
        onBlur={(event) => handleInputBlur(event)}
        globalKey={keys.inputEmail}
        defaultValues={{
          label: labels.inputEmail,
        }}
      />

      <InputField
        type="tel"
        name="phone"
        id="phone-input"
        value={formData.phone}
        onChange={onChangeInput}
        onFocus={(event) => handleInputFocus(event)}
        onBlur={(event) => handleInputBlur(event)}
        globalKey={keys.inputPhone}
        defaultValues={{
          label: labels.inputPhone,
        }}
      />

      <InputField
        type="tel"
        name="socialsecuritynumber"
        id="socialsecuritynumber-input"
        value={formData?.socialsecuritynumber}
        onChange={onChangeInput}
        onFocus={(event) => handleInputFocus(event)}
        onBlur={(event) => handleInputBlur(event)}
        globalKey={keys.inputSsn}
        defaultValues={{
          label: labels.inputSsn,
          placeholder: placeholders.inputSsn,
        }}
      />

      <div>
        <Button
          onClick={getAddress}
          disabled={formData?.socialsecuritynumber?.length !== 12}
          error={getAddressError}
          id="get-address-button"
        >
          {findGlobalContent({
            globalData,
            key: keys.buttonGetAddress,
            defaultValue: texts.buttonGetAddress,
          })}
        </Button>

        {Boolean(
          formData?.socialsecuritynumber?.length !== 12 &&
            !manualPersonalDetailsFormIsOpen
        ) && (
          <Typography variant="button-hint-text">
            {findGlobalContent({
              globalData,
              key: keys.getAddressHint,
              defaultValue: texts.getAddressHint,
            })}
          </Typography>
        )}
      </div>

      {Boolean(!addressIsFetched && !manualPersonalDetailsFormIsOpen) && (
        <Button
          variant="tertiary"
          onClick={() => setManualPersonalDetailsFormIsOpen(true)}
          id="open-manual-address-form-button"
        >
          {findGlobalContent({
            globalData,
            key: keys.buttonManualDetails,
            defaultValue: texts.buttonManualDetails,
          })}
        </Button>
      )}

      {Boolean(addressIsFetched || manualPersonalDetailsFormIsOpen) && (
        <>
          <InputField
            name="firstname"
            id="firstname-input"
            value={formData.firstname}
            onChange={onChangeInput}
            onFocus={(event) => handleInputFocus(event)}
            onBlur={(event) => handleInputBlur(event)}
            globalKey={keys.inputFirstname}
            defaultValues={{
              label: labels.inputFirstname,
            }}
          />

          <InputField
            name="lastname"
            id="lastname-input"
            value={formData.lastname}
            onChange={onChangeInput}
            onFocus={(event) => handleInputFocus(event)}
            onBlur={(event) => handleInputBlur(event)}
            globalKey={keys.inputLastname}
            defaultValues={{
              label: labels.inputLastname,
            }}
          />

          <InputField
            name="address"
            id="address-input"
            value={formData.address}
            onChange={onChangeInput}
            onFocus={(event) => handleInputFocus(event)}
            onBlur={(event) => handleInputBlur(event)}
            globalKey={keys.inputAddress}
            defaultValues={{
              label: labels.inputAddress,
            }}
          />

          <InputField
            name="zip"
            id="zip-code-input"
            value={formData.zip}
            onChange={onChangeInput}
            onFocus={(event) => handleInputFocus(event)}
            onBlur={(event) => handleInputBlur(event)}
            globalKey={keys.inputZip}
            defaultValues={{
              label: labels.inputZip,
            }}
          />

          <InputField
            name="city"
            id="city-input"
            value={formData.city}
            onChange={onChangeInput}
            onFocus={(event) => handleInputFocus(event)}
            onBlur={(event) => handleInputBlur(event)}
            globalKey={keys.inputCity}
            defaultValues={{
              label: labels.inputCity,
            }}
          />
        </>
      )}
    </Card>
  );
};

export default PersonalDetailsForm;
