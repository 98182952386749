import { useState } from "react";
import clsx from "clsx";

// functions
import { deviceType } from "../../functions/ua";

// components
import Typography from "../Typography/Typography";
import Icon from "../Icon/Icon";
import Button from "../Button/Button";

// styles
import styles from "./BankID.module.scss";

const BankID = ({
  className,
  title,
  showLoader,
  qr = null,
  billectaRequestState,
  billectaErrorMessage = null,
  openBankIdApp,
  children,
}) => {
  const [showMobileOpenBankIdButton, setShowMobileOpenBankIdButton] = useState(
    true
  );

  const isMobile = deviceType === "mobile";

  const isPolling =
    billectaRequestState === "polling-bankaccounts" ||
    billectaRequestState === "polling-signage";

  const isFailed =
    billectaRequestState === "failed-bankaccounts" ||
    billectaRequestState === "failed-signage";

  const isSignageSuccess = billectaRequestState === "success-signage";

  const defaultErrorMessage =
    billectaRequestState === "failed-bankaccounts"
      ? "Kunde inte hämta bankkonto. Fyll i uppgifterna manuellt istället eller testa igen om en stund."
      : "Någonting gick fel. Hör av dig till webbansvarig för hjälp.";

  return (
    <div className={clsx(styles["bank-id"], className)}>
      <Icon className={styles["logo"]} variant="bank-id" />
      
      {showLoader ? (
        <Icon className={styles["loader"]} variant="loader" size="large" />
      ) : (
        <>
          {title && (
            <Typography className={styles["title"]} variant="headline-2">
              {title}
            </Typography>
          )}

          {isPolling && !isMobile && (
            <Icon
              className={clsx(styles["loader"], styles["desktop"])}
              variant="loader"
              size="large"
            />
          )}

          {qr && !isMobile && (
            <>
              <div className={styles["qr-instructions"]}>
                <Typography>
                  Om BankID inte automatiskt ber dig legitimera, scanna
                  QR-koden.
                </Typography>
              </div>
              <div className={styles["qr-wrapper"]}>
                <img
                  className={clsx(
                    styles["qr-code"],
                    qr?.type && styles[qr.type]
                  )}
                  src={qr.image}
                  alt="QR"
                />
              </div>
            </>
          )}

          {isMobile && showMobileOpenBankIdButton && isPolling && (
            <Button
              className={styles["open-app-button"]}
              onClick={() => {
                openBankIdApp();
                setShowMobileOpenBankIdButton(false);
              }}
            >
              Öppna BankID på denna enhet
            </Button>
          )}

          {isPolling && (
            <Typography>
              <strong>Den kan ta upp till 45 sek</strong> att få återkoppling
              från din bank efter att du har legitimerat dig. Vänligen vänta och
              stäng ej ned fönstret.
            </Typography>
          )}

          {isPolling && isMobile && (
            <Icon
              className={clsx(styles["loader"], styles["mobile"])}
              variant="loader"
              size="large"
            />
          )}

          {isFailed && (
            <>
              <Icon
                className={styles["error-icon"]}
                variant="info-triangle"
                size="large"
              />
              <Typography>
                {billectaErrorMessage
                  ? billectaErrorMessage
                  : defaultErrorMessage}
              </Typography>
            </>
          )}

          {isSignageSuccess && (
            <>
              <Icon
                className={styles["loader"]}
                variant="loader"
                size="large"
              />
              <Typography className={styles["success-text"]}>
                Signeringen lyckades!
              </Typography>
            </>
          )}
        </>
      )}
      {children}
    </div>
  );
};
export default BankID;
