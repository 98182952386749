import { useCallback, useContext, useEffect, useState } from "react";
import parse from "html-react-parser";

// functions
import { AppdataContext, AppdataDispatchContext } from "../../../../functions/appdataContext";
import { handleAbortSignage } from "../../../../functions/billectaCalls";
import { findGlobalContent } from "../../../../functions/findGlobalContent";

// components
import Modal from "../../../Modal/Modal";
import Checkbox from "../../../Checkbox/Checkbox";
import BankaccountForm from "./components/BankaccountForm";
import BankID from "../../../BankID/BankID";

const RecurringPaymentForm = ({
  globalData = {},
  titlePrefixNumber,
  manualBankAccountFormIsOpen,
  setManualBankAccountFormIsOpen,
  onChangeInput = () => {},
  onChangeInputRadioOrCheckbox = () => {},
  handleInputFocus = () => {},
  handleValidation = () => {},
}) => {
  const appdata = useContext(AppdataContext);
  const dispatch = useContext(AppdataDispatchContext);
  const [hasRedirectedToReturnURL, setHasRedirectedToReturnURL] = useState(false);

  const billectaRequestState = appdata?.billectaAutogiro?.requestState;
  const billectaErrorMessage = appdata?.billectaAutogiro?.errorMessage;
  const qrCode = appdata?.billectaAutogiro?.qrCode;
  const { formData } = appdata;

  const isIOS = navigator?.userAgent && /iPad|iPhone|iPod/.test(navigator.userAgent);

  // global content keys
  const keys = {
    title: "title_recurringPayment",
    text: "text_recurringPayment",
    bankClearing: "input_bankClearing",
    bankAccount: "input_bankAccount",
    bankSelect: "select_chooseBank",
    getBankAccount: "button_getBankAccount",
    getBankAccountHint: "text_getBankAccountHint",
    useBankId: "text_modalUseBankId",
    useBankIdMobile: "button_modalUseBankIdMobile",
    useBankIdOtherUnit: "button_modalUseBankIdOtherUnit",
    manualBankAccount: "button_manualBankAccount",
    approvalRecurringPayment: "checkbox_approvalRecurringPayment",
  };

  // default values - other
  const texts = {
    title: "Betalning via autogiro",
    labelBank: "Bank",
    getBankAccount: "Hämta bankkonto med BankID",
    getBankAccountHint: 'Fyll i bank samt personnummer under "Dina personuppgifter" för att kunna hämta ditt bankkonto med BankID.',
    useBankId: "Öppna BankID på din mobil för att hämta bankkonto.",
    useBankIdMobile: "Använd Mobilt BankID",
    useBankIdOtherUnit: "Öppna BankID på annan enhet",
    manualBankAccount: "Fyll i kontouppgifterna själv",
    useBankISignage: "Öppna BankID på din mobil för att slutföra signeringen.",
    approvalRecurringPayment: `<p>Jag godkänner <a href="#">villkoren för autogiro</a>.</p>`,
  };

  // default values - input field & select labels
  const labels = {
    bankClearing: "Clearingnummer",
    bankAccount: "Bankkontonummer",
    bankSelect: "Bank",
    bankAccountSelect: "Välj bankkonto",
  };

  // default values select placeholder option
  const placeholderOptions = {
    bankSelect: "Välj bank",
    bankAccountSelect: "Välj konto",
  };
  const bankIdAutostartToken = appdata?.billectaAutogiro?.bankIdAutostartToken;
  const redirectURL = appdata?.billectaAutogiro?.redirectURL;

  const shouldShowModal =
    billectaRequestState === "requesting-signage" ||
    billectaRequestState === "polling-signage" ||
    billectaRequestState === "failed-signage" ||
    billectaRequestState === "success-signage";

  const openBankIdApp = useCallback(() => {
    const linkBase = isIOS ? `https://app.bankid.com/` : `bankid:///`;

    if (bankIdAutostartToken) {
      window.location = `${linkBase}?autostarttoken=${bankIdAutostartToken}&redirect=null`;
    } else {
      window.location = `${linkBase}?redirect=null`;
    }
  }, [bankIdAutostartToken, isIOS]);

  useEffect(() => {
    if (billectaRequestState === "success-signage" && !hasRedirectedToReturnURL && redirectURL) {
      setHasRedirectedToReturnURL(true);
      window.location = redirectURL;
    }
  }, [
    redirectURL,
    billectaRequestState,
    hasRedirectedToReturnURL,
    setHasRedirectedToReturnURL,
  ]);

  return (
    <>
      <BankaccountForm
        globalData={globalData}
        formData={formData}
        onChangeInput={onChangeInput}
        titlePrefixNumber={titlePrefixNumber}
        keys={keys}
        texts={texts}
        labels={labels}
        placeholderOptions={placeholderOptions}
        manualBankAccountFormIsOpen={manualBankAccountFormIsOpen}
        setManualBankAccountFormIsOpen={setManualBankAccountFormIsOpen}
        handleInputFocus={handleInputFocus}
        handleValidation={handleValidation}
      />

      <Modal
        isActive={shouldShowModal}
        closeModal={() => handleAbortSignage(dispatch)}
        variant="white"
        size="small"
      >
        <BankID
          title={
            findGlobalContent({
              globalData,
              key: keys.useBankISignage, // TODO - add global values for this
              defaultValue: texts.useBankISignage,
            })
          }
          showLoader={billectaRequestState === "requesting-signage"}
          qr={billectaRequestState === "polling-signage" && qrCode ? qrCode : null}
          billectaRequestState={billectaRequestState}
          billectaErrorMessage={billectaErrorMessage}
          openBankIdApp={openBankIdApp}
        />
      </Modal>

      <Checkbox
        id="autogiro_optIn"
        name="autogiro_optIn"
        value={formData?.autogiro_optIn}
        onCheckedChange={(value) => onChangeInputRadioOrCheckbox("autogiro_optIn", value)}
      >
        {parse(
          findGlobalContent({
            globalData,
            key: keys.approvalRecurringPayment,
            defaultValue: texts.approvalRecurringPayment,
          })
        )}
      </Checkbox>
    </>
  );
};

export default RecurringPaymentForm;
