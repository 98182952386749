import axios from "axios";

// functions
import { getErrorMessage } from "../functions/getErrorMessage";
import { getUnmaskedValue } from "../functions/maskString";

const POLLING_INTERVAL_DURATION = 1000; // 1 sek
const GRACE_PERIOD_DURATION = 3000; // 3 sek

const initiateGetBankAccounts = async (
  { bank, socialsecuritynumber, otherDevice },
  stateRef,
  dispatch
) => {
  if (!bank || !socialsecuritynumber) {
    //console.log("missing bank or social security number");
    return;
  }

  const appdata = stateRef.current;
  const { ajax_url, nonce } = appdata?.backendSetup;

  dispatch({
    type: "setBillectaStatus",
    value: "requesting-bankaccounts",
  });

  let get_bank_account_data = new FormData();
  get_bank_account_data.set(
    "action",
    "ktk_donations_initiate_get_bank_account"
  );
  get_bank_account_data.set("nonce", nonce);
  get_bank_account_data.set("socialsecuritynumber", socialsecuritynumber);
  get_bank_account_data.set("bank", bank);
  get_bank_account_data.set("otherDevice", otherDevice);

  try {
    const res = await axios.post(ajax_url, get_bank_account_data);
    if (
      stateRef.current?.billectaAutogiro?.requestState !==
      "requesting-bankaccounts"
    ) {
      return;
    }
    await new Promise((res) => setTimeout(res, GRACE_PERIOD_DURATION));
    if (
      stateRef.current?.billectaAutogiro?.requestState !==
      "requesting-bankaccounts"
    ) {
      return;
    }
    if (res.data?.PublicId) {
      dispatch({
        type: "setBillectaPublicID",
        value: res.data?.PublicId,
      });
      pollBankAccount(res.data?.PublicId, stateRef, dispatch);
    } else {
      console.error("Something went wrong");
      dispatch({ type: "setBillectaStatus", value: "failed-bankaccounts" });
      handleFailedBankAccounts(dispatch);
      if (res.data?.error) {
        dispatch({ type: "setBillectaErrorMessage", value: res.data.error });

        // Loop through all the fields with errors to set error message
        for (var index in res.data.fields) {
          dispatch({
            type: "setFormErrorValue",
            value: {
              name: res.data.fields[index],
              value: getErrorMessage(res.data.fields[index]),
            },
          });
        }
      }
    }
  } catch (err) {
    //console.log(err);
    dispatch({ type: "setBillectaStatus", value: "failed-bankaccounts" });
    handleFailedBankAccounts(dispatch);
  }
};

const pollBankAccount = async (publicId, stateRef, dispatch) => {
  await new Promise((res) => setTimeout(res, 0));

  const appdata = stateRef.current;
  const { ajax_url, nonce } = appdata?.backendSetup;
  const { qrCode, bankIdAutostartToken } = appdata?.billectaAutogiro;

  if (
    stateRef.current?.billectaAutogiro?.requestState !==
      "polling-bankaccounts" &&
    stateRef.current?.billectaAutogiro?.requestState !==
      "requesting-bankaccounts"
  ) {
    return;
  }

  if (
    stateRef.current?.billectaAutogiro?.requestState === "polling-bankaccounts"
  ) {
    await new Promise((res) => setTimeout(res, POLLING_INTERVAL_DURATION));
  }

  let poll_bank_account_data = new FormData();
  poll_bank_account_data.append(
    "action",
    "ktk_donations_poll_get_bank_account"
  );
  poll_bank_account_data.append("nonce", nonce);
  poll_bank_account_data.append("ref", publicId);

  try {
    const res = await axios.post(ajax_url, poll_bank_account_data);
    if (
      stateRef.current?.billectaAutogiro?.requestState !==
        "polling-bankaccounts" &&
      stateRef.current?.billectaAutogiro?.requestState !==
        "requesting-bankaccounts"
    ) {
      return;
    }
    if (
      stateRef.current?.billectaAutogiro?.requestState ===
      "requesting-bankaccounts"
    ) {
      dispatch({
        type: "setBillectaStatus",
        value: "polling-bankaccounts",
      });
    }
    if (res.data) {
      const pollStatus = res.data?.status?.toLowerCase();
      if (pollStatus === "waiting") {
        if (res.data?.qr) {
          dispatch({
            type: "setBillectaQRCode",
            value: {
              image: res.data.qr,
            },
          });
        }
        if (!bankIdAutostartToken && res.data?.BankIdAutostartToken) {
          dispatch({
            type: "setBillectaBankIDToken",
            value: res.data.BankIdAutostartToken,
          });

          // Check if the qr code is likely to be a small one (Swedbank)
          if (res.data?.qr && res.data.BankIdAutostartToken.match("bankid.")) {
            dispatch({
              type: "setBillectaQRCode",
              value: {
                image: res.data.qr,
                type: "small",
              },
            });
          }
        }
        // Still waiting for signage
        Promise.resolve().then(() =>
          pollBankAccount(publicId, stateRef, dispatch)
        );
      } else if (pollStatus === "success") {
        dispatch({ type: "setBillectaBankAccounts", value: res.data.accounts });
        dispatch({ type: "setBillectaStatus", value: "success-bankaccounts" });
      } else if (pollStatus === "failed") {
        console.error("failed to get bank accounts, show somehow");
        dispatch({ type: "setBillectaStatus", value: "failed-bankaccounts" });
        handleFailedBankAccounts(dispatch);
        dispatch({
          type: "setBillectaErrorMessage",
          value:
            "Kunde inte hämta bankkonto. Fyll i uppgifter manuellt istället.",
        });
        dispatch({
          type: "setBillectaErrorMessage",
          value: res.data.FailureMessage,
        });
      } else {
        //console.log("Unknown status", pollStatus);
      }
    } else {
      //console.error("Something went wrong");
      dispatch({ type: "setBillectaStatus", value: "failed-bankaccounts" });
      handleFailedBankAccounts(dispatch);
    }
  } catch (err) {
    //console.log(err);
    if (err.code === "ERR_NETWORK") {
      Promise.resolve().then(() =>
        pollBankAccount(publicId, stateRef, dispatch)
      );
    } else {
      dispatch({ type: "setBillectaStatus", value: "failed-bankaccounts" });
      handleFailedBankAccounts(dispatch);
    }
  }
};

const initiateSignBankId = async (eventTarget, stateRef, dispatch) => {
  dispatch({
    type: "setBillectaStatus",
    value: "requesting-signage",
  });

  dispatch({
    type: "setSubmitIsLoading",
    value: true,
  });

  const appdata = stateRef.current;
  const { formData, initialServerData } = appdata;
  const { ajax_url, nonce, post_id } = appdata?.backendSetup;

  let form_data = new FormData(eventTarget);
  form_data.set("action", "ktk_donations_initiate_bankid_signature");
  form_data.set("nonce", nonce);
  form_data.set("donation_type", formData.donation_type);
  form_data.set("campaign_id", initialServerData.campaign_id);
  form_data.set("cancelUrl", initialServerData.current_url);
  form_data.set("returnUrl", initialServerData.thank_you_url);
  form_data.set("form_id", post_id);

  for (var key in formData) {
    form_data.set(key, formData[key]);
  }

  if (formData?.isMaskedPersonalDetails) {
    form_data.set("firstname", getUnmaskedValue("firstname", formData));
    form_data.set("lastname", getUnmaskedValue("lastname", formData));
    form_data.set("address", getUnmaskedValue("address", formData));
    form_data.set("zip", getUnmaskedValue("zip", formData));
    form_data.set("city", getUnmaskedValue("city", formData));
  }

  try {
    const res = await axios.post(ajax_url, form_data);
    if (
      stateRef.current?.billectaAutogiro?.requestState !== "requesting-signage"
    ) {
      return;
    }
    if (res.data?.ReferenceToken) {
      dispatch({
        type: "setBillectaStatus",
        value: "polling-signage",
      });
      dispatch({
        type: "setBillectaReferenceToken",
        value: res.data?.ReferenceToken,
      });
      if (res.data?.AutoStartToken) {
        dispatch({
          type: "setBillectaBankIDToken",
          value: res.data?.AutoStartToken,
        });
      }
      if (res.data?.QR) {
        dispatch({
          type: "setBillectaQRCode",
          value: {
            image: res.data.QR,
          },
        });
      }

      pollSignage(res.data?.ReferenceToken, stateRef, dispatch);

      dispatch({
        type: "setSubmitIsLoading",
        value: false,
      });
    } else {
      console.error("Something went wrong. No reference token");
      dispatch({ type: "setBillectaStatus", value: "failed-signage" });
      handleFailedSignage(dispatch);
      // if error
      if (res.data?.error) {
        dispatch({
          type: "setBillectaErrorMessage",
          value: "Ett fel har inträffat. Var god försök igen.",
        });
      }

      // Loop through all the fields with errors to set error message
      for (var index in res.data.fields) {
        dispatch({
          type: "setFormErrorValue",
          value: {
            name: res.data.fields[index],
            value: getErrorMessage(res.data.fields[index]),
          },
        });
      }

      dispatch({
        type: "setSubmitIsLoading",
        value: false,
      });
    }
  } catch (e) {
    console.error(e);
    dispatch({ type: "setBillectaStatus", value: "failed-signage" });
    handleFailedSignage(dispatch);
    dispatch({
      type: "setBillectaErrorMessage",
      value: "Kunde inte påbörja signering.",
    });
    dispatch({
      type: "setSubmitIsLoading",
      value: false,
    });
  }
};

const pollSignage = async (referenceToken, stateRef, dispatch) => {
  await new Promise((res) => setTimeout(res, 0));

  // TODO - set up timout

  const appdata = stateRef.current;
  const { ajax_url, nonce } = appdata?.backendSetup;

  if (stateRef.current?.billectaAutogiro?.requestState !== "polling-signage") {
    return;
  }

  await new Promise((res) => setTimeout(res, POLLING_INTERVAL_DURATION));

  let poll_bankid_signature_data = new FormData();
  poll_bankid_signature_data.append(
    "action",
    "ktk_donations_poll_bankid_signature"
  );
  poll_bankid_signature_data.append("nonce", nonce);
  poll_bankid_signature_data.append("token", referenceToken);

  try {
    const res = await axios.post(ajax_url, poll_bankid_signature_data);
    if (res.data) {
      const pollStatus = res.data?.status?.toLowerCase();
      //console.log(pollStatus);
      if (pollStatus === "started") {
        // Still waiting for signage + updated QR code

        if (res.data?.QR) {
          dispatch({
            type: "setBillectaQRCode",
            value: {
              image: res.data.QR,
            },
          });
        }

        Promise.resolve().then(() =>
          pollSignage(referenceToken, stateRef, dispatch)
        );
      } else if (pollStatus === "complete") {
        dispatch({ type: "setBillectaStatus", value: "success-signage" });
        dispatch({ type: "setBillectaRedirectURL", value: res.data?.url });
      } else if (pollStatus === "error") {
        console.error("failed to sign, show somehow");
        dispatch({ type: "setBillectaStatus", value: "failed-signage" });
        handleFailedSignage(dispatch);
        dispatch({
          type: "setBillectaErrorMessage",
          value: "Signering misslyckades.",
        });
      } else {
        //console.log("Unknown status", pollStatus);
        // Possible values:
        // "OutstandingTransaction",
        // "NoClient",
        // "Started",
        // "UserSign",
        // "UserReq",
        // "Complete",
        // "Error"
      }
    } else {
      console.error("Something went wrong");
      dispatch({ type: "setBillectaStatus", value: "failed-signage" });
      handleFailedSignage(dispatch);
      dispatch({
        type: "setBillectaErrorMessage",
        value: "Signering misslyckades.",
      });
    }
  } catch (err) {
    console.log(err);
    if (err.code === "ERR_NETWORK") {
      Promise.resolve().then(() =>
        pollSignage(referenceToken, stateRef, dispatch)
      );
    } else {
      dispatch({ type: "setBillectaStatus", value: "failed-signage" });
      handleFailedSignage(dispatch);
      dispatch({
        type: "setBillectaErrorMessage",
        value: "Signering misslyckades.",
      });
    }
  }
};

const handleFailedBankAccounts = (dispatch) => {
  dispatch({
    type: "setBillectaPublicID",
    value: null,
  });
  dispatch({
    type: "setBillectaBankIDToken",
    value: null,
  });
  dispatch({
    type: "setBillectaQRCode",
    value: null,
  });
};

const handleAbortBankAccounts = (dispatch) => {
  dispatch({
    type: "setBillectaStatus",
    value: "idle",
  });
  dispatch({
    type: "setSubmitIsLoading",
    value: false,
  });
  dispatch({
    type: "setBillectaErrorMessage",
    value: null,
  });
  dispatch({
    type: "setBillectaPublicID",
    value: null,
  });
  dispatch({
    type: "setBillectaBankIDToken",
    value: null,
  });
  dispatch({
    type: "setBillectaQRCode",
    value: null,
  });
};

const handleFailedSignage = (dispatch) => {
  dispatch({
    type: "setBillectaReferenceToken",
    value: null,
  });
  dispatch({
    type: "setBillectaBankIDToken",
    value: null,
  });
  dispatch({
    type: "setBillectaQRCode",
    value: null,
  });
};

const handleAbortSignage = (dispatch) => {
  dispatch({
    type: "setBillectaStatus",
    value: "idle",
  });
  dispatch({
    type: "setSubmitIsLoading",
    value: false,
  });
  dispatch({
    type: "setBillectaErrorMessage",
    value: null,
  });
  dispatch({
    type: "setBillectaReferenceToken",
    value: null,
  });
  dispatch({
    type: "setBillectaBankIDToken",
    value: null,
  });
  dispatch({
    type: "setBillectaQRCode",
    value: null,
  });
};

export {
  initiateGetBankAccounts,
  initiateSignBankId,
  handleAbortBankAccounts,
  handleAbortSignage,
  handleFailedBankAccounts,
  handleFailedSignage,
};
