import { forwardRef } from "react";
import clsx from "clsx";

// styles
import styles from "./Button.module.scss";

// components
import Typography from "../Typography/Typography";
import Icon from "../Icon/Icon";

const Button = forwardRef(
  (
    {
      className,
      type = "button",
      onClick,
      children,
      disabled,
      variant = "primary",
      circular = false,
      error = null,
      id,
      isBankIdButton = false,
    },
    ref
  ) => (
    <div className={styles["button-wrapper"]}>
      <button
        ref={ref}
        type={type}
        id={id}
        className={clsx(
          styles["button"],
          className,
          circular ? styles["circular"] : styles["full-width"],
          styles[variant],
          disabled && styles["disabled"]
        )}
        onClick={onClick}
        disabled={disabled}
      >
        {typeof children === "string" ? (
          <Typography
            variant="button"
            color={variant === "primary" ? "white" : "black"}
            className={styles["button-text"]}
          >
            {children}
          </Typography>
        ) : (
          children
        )}

        {isBankIdButton && (
          <Icon variant="bank-id" className={styles["bank-id-icon"]} />
        )}
      </button>
      
      {error && <div className={styles["error-message"]}>{error}</div>}
    </div>
  )
);

export default Button;
